  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Roboto;
      font-weight: 500;
  }

  .main-page {
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .graph-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 375px;
      margin: 0 auto;
  }

  /* Mobile screen breakpoint */
  @media (max-width: 768px) {
      .graph-container {
          width: 100%;
          height: 300px;
      }
  }


  .formbold-form-input {
      width: 100%;
      padding: 8px 22px;
      border-radius: 8px;
      border: 1px solid #dde3ec;
      font-weight: 400;
      font-size: 16px;
      color: #536387;
      outline: none;
      resize: none;
  }

  .formbold-form-input:disabled {
      background-color: lightgray;
      color: gray;
  }

  .formbold-form-input::placeholder {
      color: rgba(83, 99, 135, 0.5);
  }

  .formbold-form-input:focus {
      border-color: black;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }



  .roof-options-container {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      /* Removed negative margin */
      margin-left: 0;
      /* Removed negative margin */
      justify-content: center;
      /* This will center the items in the container */
  }

  .roof-option-item {
      display: flex;
      flex: 1 1 25%;
      /* Grow to fill the space, but don't shrink smaller than 25% */
      max-width: 25%;
      /* Maximum width of 25% for four items in a row */
      padding: 15px;
      /* Consistent padding around items */
      box-sizing: border-box;
  }

  @media (max-width: 768px) {
      .roof-option-item {
          flex-basis: 50%;
          /* On mobile, two items per line */
          max-width: 50%;
      }
  }


  .formbold-form-label {
      color: #1a4945;
      font-size: 14px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
      text-transform: capitalize;
      line-height: 1.5em;
  }

  .form-input {
      margin: 20px 0px;
  }

  .formbold-checkbox-label {
      display: flex;
      cursor: pointer;
      user-select: none;
      font-size: 16px;
      line-height: 24px;
      color: #536387;
  }

  .formbold-checkbox-label a {
      margin-left: 5px;
      color: #6a64f1;
  }

  .range-value {
      font-size: 24px;
      font-weight: 500;
      color: #b4c902;
  }

  .formbold-btn {
      font-size: 14px;
      border-radius: 5px;
      padding: 8px 25px;
      border: none;
      font-weight: 500;
      background-color: #b4c902;
      color: #1a4945;
      cursor: pointer;
      margin-top: 25px;
      width: 100%;
  }

  .form-header-button:hover,
  .formbold-btn:hover {
      background-color: rgb(71, 71, 71);
      color: #ffffff;
  }

  .inputform-heading {
      color: #1B4962;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.1em;
      /* letter-spacing: -1px; */
  }

  .toggleButton {
      background-color: lightgray !important;
      height: 20px;
      width: 40px !important;
  }

  .toggleButton:checked {
      background-color: #b4c902 !important;
  }

  .toggleButton:focus {
      outline: none !important;
      border: none !important;
      -webkit-outline: none !important;
  }

  .inputform-disclaimer {
      text-align: center;
      font-size: 12px;
      margin: 18px 0px 0px 0px;
  }

  .summary-label {
      font-size: 16px;
      margin: 4px;
      color: #848484;
      font-weight: 400;
  }

  .list-unstyled li {
      text-decoration: none;
      color: #1B4962;
      font-size: 13px;
  }

  .footer-copyright {
      margin: 2% 2% 0% 2%;
  }

  .list-unstyled li {
      line-height: 28px;
  }

  .list-unstyled li:hover {
      color: #b4c902;
  }

  .area-graph {
      height: 200px;
  }

  .solar-form-wrapper {
      padding: 5%;
      background-color: rgb(241, 241, 241);
  }

  .solar-main {
      display: flex;
      flex-direction: column;
      row-gap: 80px;
  }

  .bg {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 2px 2px rgb(228, 227, 227) !important;
      padding: 0px;
      margin-bottom: 40px;
  }

  .shadow-custom {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  }

  /* .form-header {
      font-size: 24px;
      padding: 10px;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center !important;
      gap: 10px;
  } */

  .opacity-25 {
      pointer-events: none;
  }

  #error {
      text-align: right;
      color: red;
      font-size: 12px;
      letter-spacing: 1px;
  }

  #zipcode-error {
      margin: 1% 0 0 4px;
      color: red;
      font-size: 12px;
  }

  input[type="range"] {
      -webkit-appearance: none;
      height: 10px !important;
      background-color: #b4c902;
      width: 100%;
      border-radius: 10px !important;
  }

  input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #4CAF50;
      cursor: pointer;
  }

  .roof-option {
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      font-weight: 500;
      gap: 10px;
      flex-wrap: nowrap;
      /* This will keep all items on a single line */
      padding-bottom: 10px;
  }

  .roof-image {
      object-fit: fit;
      width: 60px;
      height: 60px;
      margin: 1px;
      border-radius: 6px;
  }

  .radio-custom {
      opacity: 0;
      left: -9999;
      position: 'absolute';
  }

  .radio-custom:checked~img {
      border: 2px solid #000000;
      border-spacing: 5px;
  }

  .loader-container {
      position: relative;
      top: 10;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .loader-dots {
      margin: 20px 0px !important;
  }

  .loader-container-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 9999 !important;
  }

  .spinner-grow {
      margin: 0;
      padding: 0;
      background-color: #a8bb00;
      margin-top: 5%;
  }

  .main-container {
      width: 100%;
      max-width: 100%;
      /* Maximum width for large screens */
      padding: 0px;
      margin: 0 auto;
      /* Center the container */
      box-sizing: border-box;
      /* Include padding and border in the element's total width and height */
  }

  .form-header-button {
      background-color: #b4c902;
  }

  .font-color {
      color: #b4c902;
  }

  .after-price {
      font-size: 12px;
      color: #b4c902;
  }

  .step-buttons {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;
  }

  .step-button {
      cursor: pointer;
      border: none;
      background-color: #ccc;
      border-radius: 50%;
      margin: 0px 10px;
      height: 50px;
      width: 50px;
  }

  .step-button.active {
      background-color: #b4c902;
      color: white;
  }

  .step-number.active {
      color: #b4c902;
  }

  .line {
      height: 2px;
      width: 100%;
      background-color: #ccc;
  }

  .line-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
  }

  .form-line {
      display: flex;
      align-items: end;
      width: 100%;
  }

  @media (max-width: 1200px) {
      .main-container {
          padding: 15px;
      }
  }

  @media (max-width: 992px) {
      .main-container {
          padding: 10px;
      }
  }

  @media (max-width: 768px) {
      .main-container {
          padding: 5px;
      }
  }

  @media (max-width: 768px) {
      .spinner-grow {
          margin-top: 30%;
          /* This will only apply to screens smaller than 768px wide */
      }
  }


  @media (max-width: 768px) {
      .container-sm {
          padding: 0px;
      }

      .d-flex {
          flex-direction: column;
      }

      .w-md-full {
          width: 100% !important;
      }

      footer {
          text-align: center;
      }

      footer .list-unstyled li {
          font-size: 18px !important;
          line-height: 20px !important;
      }

      .footer-header {
          font-size: 24px !important;
      }

      .inputform-heading {
          font-size: 28px;
          text-align: center;
      }

      .input-image {
          width: 75% !important;
      }

      .roof-image {
          width: 50px;
          height: 50px;
      }
  }

  @media (max-width: 480px) {
      .container-sm {
          padding: 0px;
      }

      .inputform-heading {
          font-size: 20px;
          text-align: center;
      }

      .w-md-full {
          width: 90%;
          padding: 0% 6%;
      }

      .form-header {
          font-size: 20px;
      }

      .footer-header {
          font-size: 20px !important;
      }

      footer .list-unstyled li {
          font-size: 14px !important;
      }

      .summary-label {
          font-size: 14px;
          margin: 2px;
      }

      #error {
          font-size: 12px;
      }

      .area-graph {
          height: 300px;
      }

      .form-header-button {
          transform: scale(0.8);
      }
  }